<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <b-card
            no-body
            header-bg-variant="bluenavy"
            header="Iscrizione Partecipanti"
            header-text-variant="white"
            header-tag="h2"
            border-variant="bluenavy"
        >
            <template #header>
                <div style="margin: -2rem -2.25rem; padding: 2rem 2.25rem;">
                    <div class="row">
                        <div class="col-md-10">
                            <h2>Denominazione gara/evento: {{competition.name}}</h2>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-block btn-primary font-weight-bolder" @click.prevent="goBack"><i class="fas fa-arrow-left"></i> Indietro</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <h6>Totale Partecipanti: {{competition.num_competitors}}</h6>
                        </div>
                        <div class="col-md-4">
                            <h6>Data Svolgimento: {{competition.date}}</h6>
                        </div>
                        <div class="col-md-4">
                            <button v-if="(((competition.idrseventactivitytype === 3) && ($store.getters.currentUser.usertype === 'Sodalizio') && ($store.getters.currentUser.adminsportingclub.includes(competition.idsportingclub))) || (['Superadmin', 'Segreteria Nazionale'].includes($store.getters.currentUser.usertype)) || (($store.getters.currentUser.usertype === 'Comitato') && ($store.getters.currentUser.admincommittee === competition.idcommittee)))" type="button" class="btn btn-block btn-danger font-weight-bolder" @click.prevent="exportAllCompetitors"><i class="fas fa-download"></i>
                                <span v-if="competition.idrseventactivitytype === 2">Esporta lista completa partecipanti</span>
                                <span v-if="[1, 3].includes(competition.idrseventactivitytype)">Esporta lista completa sodalizi</span>
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <b-card-text>
                <b-alert show variant="secondary" dismissible>
                    <p v-if="[1, 3].includes(competition.idrseventactivitytype)">
                        In questa schermata bisogna caricare i partecipanti all'evento selezionando il sodalizio dal menu a tendina o inserendo manualmente il codice fiscale del sodalizio. Per l'attività sportiva e didattica non è più necessario inserire il codice fiscale del partecipante ma dovrà essere specificato per ogni ASD/SSD il numero dei partecipanti.<br>
                        <br>
                        Per l'attività sportiva è possibile effettuare un caricamento massivo dei partecipanti, attraverso il file excel compilando le colonne: Codice fiscale sodalizio e Numero partecipanti.<br>
                        <br>
                        Una volta aggiunti  i partecipanti cliccare sul pulsante <strong>SALVA</strong>.
                    </p>

                    <p v-if="[2].includes(competition.idrseventactivitytype)">
                        In questa schermata bisogna caricare i partecipanti all'evento selezionando il sodalizio dal menu a tendina o inserendo manualmente il codice fiscale del sodalizio. Per l'attività formativa è obbligatorio selezionare per ciascun sodalizio il partecipante/tesserato oppure usare il caricamento massivo partecipanti, tramite il file excel/ods compilando le colonne: Codice fiscale sodalizio, Nome, Cognome, Codice fiscale partecipante.
                    </p>
                </b-alert>
            </b-card-text>

            <b-card-text v-if="(((competition.idrseventactivitytype === 3) && ($store.getters.currentUser.usertype === 'Sodalizio') && ($store.getters.currentUser.adminsportingclub.includes(competition.idsportingclub))) || (['Superadmin', 'Segreteria Nazionale'].includes($store.getters.currentUser.usertype)) || (($store.getters.currentUser.usertype === 'Comitato') && ($store.getters.currentUser.admincommittee === competition.idcommittee)))" class="px-3">
                <div class="row mt-3">
                    <div class="col-md-4 text-center">
                        <strong>1 SCARICA IL FILE</strong><br>
                        <a v-if="[1, 3].includes(competition.idrseventactivitytype)" href="media/Modello_import_partecipanti_attivita_sportiva_didattica.ods" target="_blank" class="btn btn-block btn-success font-weight-bolder"><i class="fas fa-universal-access"></i> Modello ODS</a>
                        <a v-if="[1, 3].includes(competition.idrseventactivitytype)" href="media/Modello_import_partecipanti_attivita_sportiva_didattica.xls" target="_blank" class="btn btn-block btn-success font-weight-bolder"><i class="fas fa-file-excel"></i> Modello XLS</a>

                        <a v-if="[2].includes(competition.idrseventactivitytype)" href="media/Modello_import_partecipanti_attivita_formativa.ods" target="_blank" class="btn btn-block btn-success font-weight-bolder"><i class="fas fa-universal-access"></i> Modello ODS</a>
                        <a v-if="[2].includes(competition.idrseventactivitytype)" href="media/Modello_import_partecipanti_attivita_formativa.xls" target="_blank" class="btn btn-block btn-success font-weight-bolder"><i class="fas fa-file-excel"></i> Modello XLS</a>
                    </div>

                    <div class="col-md-4 text-center">
                        <strong>2 CARICA E PROCESSA</strong><br>
                        <div id="epsupload">
                            <FileUpload ref="fileupload" id="filecompetitors" name="filecompetitors" :multiple="false" v-model="filecompetitors" btnclass="primary" btntext="Seleziona file"></FileUpload>

                            <div v-for="(error, index) in fileErrors.filename" class="invalid-feedback d-block">{{error}}</div>
                        </div>
                        <button type="button" class="btn btn-block btn-primary font-weight-bolder" @click.prevent="processCompetitors"><i class="far fa-save"></i> Carica e Processa</button>
                    </div>

                    <div class="col-md-4 text-center">
                        <strong>3 VISUALIZZA E GESTISCI</strong><br>

                        <button v-if="competition.id && competition.type && competition.idrseventactivitytype === 2" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="showAcsiCompetitorsModal"><i class="far fa-eye"></i> Visualizza/gestisci partecipanti non ACSI</button>

                        <button v-if="competition.id && competition.type && competition.idrseventactivitytype === 2" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportCompetitorErrors"><i class="fas fa-download"></i> Esporta partecipanti con anomalie</button>
                        <button v-if="competition.id && competition.type && [1, 3].includes(competition.idrseventactivitytype)" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportSportingclubErrors"><i class="fas fa-download"></i> Esporta sodalizi con anomalie</button>

                        <button v-if="competition.id && competition.type && competition.idrseventactivitytype === 2" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportNotACSI"><i class="fas fa-download"></i> Esporta partecipanti non ACSI</button>
                        <button v-if="competition.id && competition.type && [1, 3].includes(competition.idrseventactivitytype)" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportNotACSISportingclubs"><i class="fas fa-download"></i> Esporta sodalizi non ACSI</button>
                    </div>

                    <b-modal v-if="competition.type && competition.id" ref="acsiCompetitorsModal" size="lg" hide-footer title="Visualizza/gestisci partecipanti non ACSI">
                        <SmartTable
                            ref="acsicompetitors"
                            :url="'/rseps/subscription/acsicompetitors-' + competition.type + '/index'"
                            :is_dialog="true"
                            :base_url="'/rseps/subscription/acsicompetitors-' + competition.type"
                            :base_path="'/rseps/subscription/acsicompetitors-' + competition.type"
                            :fixed_filters="competition.type === 'event' ? [{ name: 'idevent', filter: competition.id }] : [{ name: 'idcompetition', filter: competition.id }]"
                            custom_class="mx-0 px-0 title-0"
                            v-on:close-modal="updateAcsiCompetitors"
                        >
                            <template v-slot:title>
                                <span></span>
                            </template>
                            <template v-slot:before-nav>
                                <div class="text-center">
                                    <a href="#" @click.prevent="removeSelectedAcsiCompetitorsModal" class="mt-2 btn btn-info font-weight-bolder mr-2">
                                        <i class="fas fa-user-minus"></i> Rimuovi Selezionati
                                    </a>
                                </div>
                            </template>
                            <template v-slot:after-nav>
                                <div class="text-center">
                                    <a href="#" @click.prevent="closeAcsiCompetitorsModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                        <i class="fas fa-window-close"></i> Chiudi
                                    </a>
                                </div>
                            </template>
                        </SmartTable>
                    </b-modal>
                </div>
            </b-card-text>

            <b-card-text v-if="((([1, 2, 3].includes(competition.idrseventactivitytype)) && ($store.getters.currentUser.usertype === 'Sodalizio') && ($store.getters.currentUser.adminsportingclub.includes(competition.idsportingclub))) || (['Superadmin', 'Segreteria Nazionale'].includes($store.getters.currentUser.usertype)) || (($store.getters.currentUser.usertype === 'Comitato') && ($store.getters.currentUser.admincommittee === competition.idcommittee)))">
                <b-alert show variant="secondary" dismissible>
                    <h4 class="alert-heading text-center">INSERIMENTO SODALIZI</h4>
                    <p>
                        Seleziona i sodalizi dalla lista o inserisci il codice fiscale del sodalizio desiderato e fai click sul pulsante &quot;Aggiungi&quot;</i>.
                    </p>
                </b-alert>
            </b-card-text>

            <div v-if="((([1, 2, 3].includes(competition.idrseventactivitytype)) && ($store.getters.currentUser.usertype === 'Sodalizio') && ($store.getters.currentUser.adminsportingclub.includes(competition.idsportingclub))) || (['Superadmin', 'Segreteria Nazionale'].includes($store.getters.currentUser.usertype)) || (($store.getters.currentUser.usertype === 'Comitato') && ($store.getters.currentUser.admincommittee === competition.idcommittee)))" class="p-4 bg-secondary rounded">
                <div class="row">
                    <div class="col-md-12 d-flex">
                        <multiselect style="width: 100%;"
                                     id="idsportingclub"
                                     v-model="idsportingclub"
                                     :options="sportingclubs"
                                     :multiple="false"
                                     :close-on-select="true"
                                     :clear-on-select="false"
                                     placeholder="Seleziona un sodalizio"
                                     label="displayname"
                                     track-by="id"
                                     :allow-empty="true"
                                     selectLabel=""
                                     selectedLabel=""
                                     deselectLabel=""
                                     showLabels=""
                                     :class="{ 'is-invalid': typeof errors['idsportingclub'] !== 'undefined' }"
                        >
                            <template v-slot:noOptions>
                                Seleziona un sodalizio
                            </template>
                        </multiselect>
                        <button class="btn btn-primary font-weight-bolder text-nowrap ml-3" type="button" @click.prevent="addSportingclub"><i class="fas fa-plus"></i> Aggiungi</button>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-8">
                        <div class="input-group">
                            <input v-model="sportingclubtaxcode" type="text" class="form-control" placeholder="Codice Fiscale Sodalizio">
                            <div class="input-group-append">
                                <button class="btn btn-primary font-weight-bolder text-nowrap ml-3" type="button" @click.prevent="addSportingclubFromTaxCode"><i class="fas fa-plus"></i> Aggiungi</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <button v-if="competition.id && competition.type && competition.idrseventactivitytype === 2" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportCompetitorsACSI"><i class="fas fa-download"></i> Esporta partecipanti ACSI</button>
                        <button v-if="competition.id && competition.type && [1, 3].includes(competition.idrseventactivitytype)" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportSportingclubsACSI"><i class="fas fa-download"></i> Esporta sodalizi ACSI</button>
                    </div>
                </div>
            </div>
        </b-card>

        <b-card-text v-if="(competition.idrseventactivitytype === 2) && (($store.getters.currentUser.usertype === 'Sodalizio') || (['Superadmin', 'Segreteria Nazionale'].includes($store.getters.currentUser.usertype)) || (($store.getters.currentUser.usertype === 'Comitato') && ($store.getters.currentUser.admincommittee === competition.idcommittee)))" class="mt-3 mb-3">
            <b-alert show variant="secondary" dismissible>
                <h4 class="alert-heading text-center">INSERIMENTO TESSERATI ACSI PER SODALIZIO</h4>
                <p>
                    Utilizza il bottone <i class="fas fa-user-plus text-primary"></i> per aggiungere i partecipanti alla gara. Per visualizzare/gestire i partecipanti aggiunti utilizza il bottone <i class="far fa-eye text-primary"></i>.
                </p>
            </b-alert>
        </b-card-text>

        <div class="table-responsive mt-3 bg-white">
            <div class="text-right mb-3" v-if="competition_sportingclubs.length"><button class="btn btn-success font-weight-bolder" type="button" @click.prevent="saveSportingclubs()"><i class="fas fa-save text-white"></i> Salva</button></div>

            <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered">
                <thead>
                    <tr>
                        <th class="bg-primary"><span class="text-white">Cod. ACSI</span></th>
                        <th class="bg-primary"><span class="text-white">Codice Fiscale</span></th>
                        <th class="bg-primary"><span class="text-white">Denominazione</span></th>
                        <th class="bg-primary"><span class="text-white">Partecipanti</span></th>
                        <th class="bg-primary text-center pr-0"><span class="text-white">Azioni</span></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-if="competition_sportingclubs.length" v-for="(sportingclub, index) in competition_sportingclubs">
                        <td>{{sportingclub.id}}</td>
                        <td>{{sportingclub.taxcode}}</td>
                        <td>{{sportingclub.displayname}}</td>
                        <td v-if="competition.idrseventactivitytype === 2" class="text-right">{{sportingclub.num_partecipanti}}</td>
                        <td v-if="(competition.idrseventactivitytype === 1 || competition.idrseventactivitytype === 3) && (typeof competition_sportingclubs[index] !== 'undefined')"><input type="number" v-model.number="competition_sportingclubs[index]['numcompetitors']" style="text-align: right; border: 1px solid black;" min="0" step="1"></td>
                        <td class="text-right">
                            <button
                                v-if="competition.can_delete_sportingclubs"
                                type="button"
                                class="btn btn-danger btn-icon btn-circle btn-sm mr-3"
                                @click.prevent="confirmDeleteSportingclub(sportingclub.id)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>

                            <button v-if="competition.idrseventactivitytype === 2"
                                    type="button"
                                    class="btn btn-primary btn-icon btn-circle btn-sm mr-3"
                                    @click.prevent="showAddAcsiCompetitorsModal(sportingclub.id)"
                            >
                                <i class="fas fa-user-plus"></i>
                            </button>

                            <button v-if="competition.idrseventactivitytype === 2"
                                    type="button"
                                    class="btn btn-primary btn-icon btn-circle btn-sm"
                                    @click.prevent="showSportingclubAcsiCompetitorsModal(sportingclub.id)"
                            >
                                <i class="far fa-eye"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="text-right mb-3" v-if="competition_sportingclubs.length"><button class="btn btn-success font-weight-bolder" type="button" @click.prevent="saveSportingclubs()"><i class="fas fa-save text-white"></i> Salva</button></div>

            <b-modal v-if="competition.type && competition.id" ref="sportingclubAcsiCompetitorsModal" size="lg" hide-footer title="Visualizza/gestisci partecipanti ACSI">
                <SmartTable
                    ref="sportingclubacsicompetitors"
                    :url="'/rseps/subscription/sportingclub/acsicompetitors-' + competition.type + '/index'"
                    :is_dialog="true"
                    :base_url="'/rseps/subscription/sportingclub/acsicompetitors-' + competition.type"
                    :base_path="'/rseps/subscription/sportingclub/acsicompetitors-' + competition.type"
                    :fixed_filters="competition.type === 'event' ? [{ name: 'idevent', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubmodal }] : [{ name: 'idcompetition', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubmodal }]"
                    custom_class="mx-0 px-0 title-0"
                    v-on:close-modal="updateSportingclubAcsiCompetitors"
                >
                    <template v-slot:title>
                        <span></span>
                    </template>
                    <template v-slot:before-nav>
                        <div class="text-center">
                            <a href="#" @click.prevent="removeSportingclubSelectedAcsiCompetitorsModal" class="mt-2 btn btn-info font-weight-bolder mr-2">
                                <i class="fas fa-user-minus"></i> Rimuovi Selezionati
                            </a>
                        </div>
                    </template>
                    <template v-slot:after-nav>
                        <div class="text-center">
                            <a href="#" @click.prevent="closeSportingclubAcsiCompetitorsModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                <i class="fas fa-window-close"></i> Chiudi
                            </a>
                        </div>
                    </template>
                </SmartTable>
            </b-modal>

            <b-modal v-if="competition.type && competition.id" ref="addAcsiCompetitorsModal" size="lg" hide-footer title="Aggiungi partecipanti ACSI">
                <SmartTable
                    ref="addacsicompetitors"
                    :url="'/rseps/subscription/sportingclub/addacsicompetitors/index'"
                    :is_dialog="true"
                    :base_url="'/rseps/subscription/sportingclub/addacsicompetitors'"
                    :base_path="'/rseps/subscription/sportingclub/addacsicompetitors'"
                    :fixed_filters="competition.type === 'event' ? [{ name: 'idrsevent', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubadd }, { name: 'idannuality', filter: idannuality }] : [{ name: 'idcompetition', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubadd }, { name: 'idannuality', filter: idannuality }]"
                    custom_class="mx-0 px-0 title-0"
                    v-on:close-modal="updateAddAcsiCompetitors"
                >
                    <template v-slot:title>
                        <span></span>
                    </template>

                    <template v-slot:before-filters>
                        <div class="row">
                            <div class="col-md-12 p-7">
                                <div class="form-group">
                                    <label for="idannuality" class="font-weight-bolder">Annualità:</label>
                                    <select class="form-control" id="idannuality" v-model="idannuality">
                                        <option v-for="annuality in annualities" :value="annuality.id">{{annuality.solarsannualitydisplay}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td class="text-nowrap pr-0 text-right">
                            <a href="#" @click.prevent="addAcsiCompetitor(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Aggiungi" alt="Aggiungi">
                                <i class="fas fa-user-plus"></i>
                            </a>
                        </td>
                    </template>
                    <template v-slot:before-nav>
                        <div class="text-center">
                            <a href="#" @click.prevent="addSelectedAcsiCompetitorsModal" class="mt-2 btn btn-info font-weight-bolder mr-2">
                                <i class="fas fa-user-plus"></i> Aggiungi Selezionati
                            </a>
                        </div>
                    </template>
                    <template v-slot:after-nav>
                        <div class="text-center">
                            <a href="#" @click.prevent="closeAddAcsiCompetitorsModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                <i class="fas fa-window-close"></i> Chiudi
                            </a>
                        </div>
                    </template>
                </SmartTable>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import ApiService from "@/core/services/api.service";
 import JwtService from "@/core/services/jwt.service";
 import SmartTable from "@/view/components/SmartTable.vue";
 import FileUpload from "@/view/components/FileUpload.vue";
 import Swal from 'sweetalert2';

 export default {
     data() {
         return {
             loading: false,

             isLoading: false,
             sportingclubs: [],
             idsportingclub: '',
             sportingclubtaxcode: '',
             errors: [],

             idsportingclubmodal: null,
             idsportingclubadd: null,

             url: '',

             filecompetitors: '',
             fileErrors: [],

             competitors: [],
             competitorsErrors: [],

             competition: {
                 id: null,
                 idrseventactivitytype: null,
                 idcommittee: null,
                 idsportingclub: null,
                 type: null,
                 name: '',
                 date: '',
                 num_competitors: 0,
                 can_delete_sportingclubs: false,
                 sportingclubs: [],
             },

             competition_sportingclubs: [],
             is_updating_numcompetitors: false,
             is_updating_sportingclubs: false,

             idannuality: '',
             annualities: [],
         }
     },

     components: {
         SmartTable,
         FileUpload,
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Portale EPS", route: "/rseps-portal" },
             { title: "Iscrizione Partecipanti", route: "" },
         ]);
     },

     created() {
         this.updateCompetition();
         this.getSportingclubs();
         this.getAnnualities();
     },

     watch: {
         $route: {
             handler: function () {
                 this.updateCompetition();
             },

             deep: true,
         },

         idannuality(newValue) {
             let vm = this;
             setTimeout(function() {
                 if (typeof vm.$refs.addacsicompetitors !== "undefined") vm.$refs.addacsicompetitors.refresh();
             }, 200);
         },
     },

     methods: {
         getAnnualities() {
            this.idannuality = '';

            let url = '/rseps/subscription/sportingclub/addacsicompetitors/load-options?type=annualities&idsportingclub=' + (this.idsportingclubadd ? this.idsportingclubadd : '0');

            ApiService.query(url)
                      .then((response) => {
                          this.annualities = response.data;

                          if (this.annualities.length) {
                              if (!this.idannuality)
                              {
                                  this.idannuality = this.annualities[0].id;
                              }
                          }
                      })
                      .catch((error) => {
                          console.log(error);
                      })
         },

         saveSportingclubs() {
             Swal.fire({
                 title: 'Conferma salvataggio sodalizi',
                 text: "Sei sicuro di voler salvare i sodalizi partecipanti?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/rseps/subscription/update-num-competitors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;
                     let data = { sportingclubs: this.competition_sportingclubs };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.is_updating_numcompetitors = true;
                                       this.updateCompetition();

                                       this.$bvToast.toast('Sodalizi partecipanti salvati correttamente.', {
                                           title: 'Sodalizi partecipanti salvati correttamente',
                                           variant: 'success',
                                           solid: true
                                       });
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si è verificato un errore nel salvataggio dei Sodalizi partecipanti.', {
                                           title: 'Errore nel salvataggio dei Sodalizi partecipanti',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }

                                   this.loading = false;
                               })
                               .catch(({ response }) => {
                                   this.$bvToast.toast('Si è verificato un errore nel salvataggio dei Sodalizi partecipanti.', {
                                       title: 'Errore nel salvataggio dei Sodalizi partecipanti',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               });
                 }
             });
         },

         getSportingclubs() {
             let url = '/rseps/subscription/get-sportingclubs';

             ApiService.query(url)
                       .then((response) => {
                           this.sportingclubs = response.data;
                       })
                       .catch((error) => {
                           console.log(error);
                       })
         },

         closeModal() {
         },

         goBack() {
             window.history.go(-1);
         },

         getApiUrl() {
             return ApiService.getApiUrl();
         },

         appendToken(url) {
             if (url) {
                 let parts = url.split('?');
                 if (parts.length >= 2) {
                     return url += '&token=' + JwtService.getToken();
                 }
                 else
                 {
                     return url += '?token=' + JwtService.getToken();
                 }
             }

             return url;
         },

         updateCompetition() {
             this.competition = {
                 id: null,
                 idrseventactivitytype: null,
                 idcommittee: null,
                 idsportingclub: null,
                 type: null,
                 name: '',
                 date: '',
                 num_competitors: 0,
                 can_delete_sportingclubs: false,
                 sportingclubs: [],
                 competition_sportingclubs: [],
             },

             this.competition.id = this.$route.params.id;
             this.competition.type = this.$route.params.type;

             this.url = '/rseps/subscription/load-options?type=competitioninfo&idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             let url = this.url;

             ApiService.query(url)
                       .then((response) => {
                           let competition = response.data;

                           for (let tmp in competition.sportingclubs) {
                               if (typeof competition.sportingclubs[tmp].numcompetitors === 'undefined' || competition.sportingclubs[tmp].numcompetitors === null) this.$set(competition.sportingclubs[tmp], 'numcompetitors', 0);
                           }

                           this.competition.idrseventactivitytype = competition.idrseventactivitytype;
                           this.competition.idcommittee = competition.idcommittee;
                           this.competition.idsportingclub = competition.idsportingclub;
                           this.competition.name = competition.name;
                           this.competition.date = competition.date;
                           this.competition.num_competitors = competition.num_competitors;
                           this.$set(this.competition, 'sportingclubs', competition.sportingclubs);
                           this.competition.can_delete_sportingclubs = competition.can_delete_sportingclubs;

                           if (!this.is_updating_numcompetitors || this.is_updating_sportingclubs) {
                               this.competition_sportingclubs = [];

                               for (let i in competition.sportingclubs) {
                                   this.competition_sportingclubs.push(JSON.parse(JSON.stringify(competition.sportingclubs[i])));
                               }

                               this.is_updating_numcompetitors = false;
                               this.is_updating_sportingclubs = false;
                           }
                       })
                       .catch((error) => {
                           console.log(error);
                       })
         },

         exportAllCompetitors() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/rseps/subscription/export-all-competitors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportNotACSI() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/rseps/subscription/export-not-acsi?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportNotACSISportingclubs() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/rseps/subscription/export-not-acsi-sportingclubs?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportCompetitorErrors() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/rseps/subscription/export-competitor-errors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportSportingclubErrors() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/rseps/subscription/export-sportingclub-errors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportCompetitorsACSI() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/rseps/subscription/export-competitors-acsi?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportSportingclubsACSI() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/rseps/subscription/export-sportingclubs-acsi?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         showAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['acsiCompetitorsModal'].show();
             }, 200);
         },

         closeAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['acsiCompetitorsModal'].hide();
             }, 200);
         },

         closeAddAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['addAcsiCompetitorsModal'].hide();
             }, 200);
         },

         updateAcsiCompetitors() {
             this.$refs.acsicompetitors.refresh();
             this.is_updating_sportingclubs = true;
             this.updateCompetition();
         },

         updateAddAcsiCompetitors() {
             this.$refs.addacsicompetitors.refresh();
             this.is_updating_sportingclubs = true;
             this.updateCompetition();
         },

         showSportingclubAcsiCompetitorsModal(idsportingclub) {
             this.idsportingclubmodal = idsportingclub;

             let vm = this;
             setTimeout(function() {
                 vm.$refs['sportingclubAcsiCompetitorsModal'].show();
             }, 200);
         },

         showAddAcsiCompetitorsModal(idsportingclub) {
             this.idsportingclubadd = idsportingclub;

            this.getAnnualities();

             let vm = this;
             setTimeout(function() {
                 vm.$refs['addAcsiCompetitorsModal'].show();
             }, 200);
         },

         closeSportingclubAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['sportingclubAcsiCompetitorsModal'].hide();
             }, 200);
         },

         updateSportingclubAcsiCompetitors() {
             this.$refs.sportingclubacsicompetitors.refresh();
             this.is_updating_sportingclubs = true;
             this.updateCompetition();
         },

         processCompetitors() {
             this.loading = true;

             let url = '/rseps/subscription/process-competitors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;
             let data = { filename: this.filecompetitors };

             this.fileErrors = {};
             this.competitorsErrors = {};

             ApiService.post(url, data)
                       .then((response) => {
                           this.$bvToast.toast('Partecipanti importati correttamente.', {
                               title: 'Partecipanti importati correttamente',
                               variant: 'success',
                               solid: true
                           });

                           this.competitors = response.data;

                           this.is_updating_sportingclubs = true;
                           this.updateCompetition();

                           this.$refs.fileupload.deleteFile(this.$refs.fileupload.files[0]);

                           this.loading = false;
                       })
                       .catch(({ response }) => {
                           if (response.status === 422) {
                               this.$bvToast.toast('Si è verificato un errore nell\'elaborazione del file: controllare i dati inseriti e riprovare.', {
                                   title: 'Errore nell\'elaborazione del file',
                                   variant: 'danger',
                                   solid: true
                               });

                               if (typeof response.data.fileerrors !== "undefined") {
                                   this.fileErrors = response.data.fileerrors;
                               }

                               if (typeof response.data.errors !== "undefined") {
                                   this.competitorsErrors = response.data.errors;
                               }
                           }
                           else {
                               console.log(response);
                           }

                           this.loading = false;
                       });
         },

         addSportingclub() {
             if (this.idsportingclub) {
                 this.loading = true;

                 let url = '/rseps/subscription/add-sportingclub?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

                 let data = { sportingclub: this.idsportingclub };

                 ApiService.post(url, data)
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.idsportingclub = '';

                                   this.$bvToast.toast('Sodalizio aggiunto correttamente', {
                                       title: 'Sodalizio aggiunto correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.is_updating_sportingclubs = true;
                                   this.updateCompetition();

                                   this.loading = false;
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta del sodalizio', {
                                       title: 'Errore nell\'aggiunta del sodalizio',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               console.log(error);

                               this.loading = false;
                           });
             }
             else {
                 Swal.fire({
                     title: 'Nessun sodalizio selezionato',
                     text: "Nessun sodalizio selezionato",
                     icon: 'warning',
                     showConfirmButton: true,
                     confirmButtonText: 'Ok',
                 });

                 this.loading = false;
             }
         },

         addSportingclubFromTaxCode() {
             if (this.sportingclubtaxcode) {
                 this.loading = true;

                 let url = '/rseps/subscription/add-sportingclub-taxcode?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

                 let data = { taxcode: this.sportingclubtaxcode };

                 ApiService.post(url, data)
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.sportingclubtaxcode = '';

                                   this.$bvToast.toast('Sodalizio aggiunto correttamente', {
                                       title: 'Sodalizio aggiunto correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.is_updating_sportingclubs = true;
                                   this.updateCompetition();

                                   this.loading = false;
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta del sodalizio', {
                                       title: 'Errore nell\'aggiunta del sodalizio',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               console.log(error);

                               this.loading = false;
                           });
             }
             else {
                 Swal.fire({
                     title: 'Nessun codice fiscale sodalizio inserito',
                     text: "Nessun codice fiscale sodalizio inserito",
                     icon: 'warning',
                     showConfirmButton: true,
                     confirmButtonText: 'Ok',
                 });

                 this.loading = false;
             }
         },

         removeSelectedAcsiCompetitorsModal() {
             Swal.fire({
                 title: 'Conferma rimozione selezionati',
                 text: "Sei sicuro di voler rimuovere i partecipanti selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     for (let i in this.$refs.acsicompetitors.items) {
                         if (this.$refs.acsicompetitors.items[i].checked) competitors.push({ idcompetitor: this.$refs.acsicompetitors.items[i].id } );
                     }

                     if (competitors.length) {
                         let url = '/rseps/subscription/remove-acsi-competitors';
                         let data = {
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti rimossi correttamente', {
                                               title: 'Partecipanti rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.acsicompetitors.refresh();
                                           this.is_updating_sportingclubs = true;
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione dei partecipanti.', {
                                               title: 'Errore nella rimozione dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare la rimozione selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         addSelectedAcsiCompetitorsModal() {
             Swal.fire({
                 title: 'Conferma aggiunta selezionati',
                 text: "Sei sicuro di voler aggiungere i partecipanti selezionati?",
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     for (let i in this.$refs.addacsicompetitors.items) {
                         if (this.$refs.addacsicompetitors.items[i].checked) competitors.push({ idcompetitor: this.$refs.addacsicompetitors.items[i].id } );
                     }

                     if (competitors.length) {
                         let url = '/rseps/subscription/add-acsi-competitors';
                         let data = {
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti aggiunti correttamente', {
                                               title: 'Partecipanti aggiunti correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.addacsicompetitors.refresh();
                                           this.is_updating_sportingclubs = true;
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta dei partecipanti.', {
                                               title: 'Errore nell\'aggiunta dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare l\'aggiunta selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         addAcsiCompetitor(idcompetitor) {
             Swal.fire({
                 title: 'Conferma aggiunta partecipante',
                 text: "Sei sicuro di voler aggiungere il partecipante selezionato?",
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     competitors.push({ idcompetitor: idcompetitor } );

                     if (competitors.length) {
                         let url = '/rseps/subscription/add-acsi-competitors';
                         let data = {
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipante aggiunto correttamente', {
                                               title: 'Partecipante aggiunto correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.addacsicompetitors.refresh();
                                           this.is_updating_sportingclubs = true;
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta del partecipante.', {
                                               title: 'Errore nell\'aggiunta del partecipante',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare l\'aggiunta selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         removeSportingclubSelectedAcsiCompetitorsModal() {
             Swal.fire({
                 title: 'Conferma rimozione selezionati',
                 text: "Sei sicuro di voler rimuovere i partecipanti selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     for (let i in this.$refs.sportingclubacsicompetitors.items) {
                         if (this.$refs.sportingclubacsicompetitors.items[i].checked) competitors.push({ idcompetitor: this.$refs.sportingclubacsicompetitors.items[i].id } );
                     }

                     if (competitors.length) {
                         let url = '/rseps/subscription/sportingclub/remove-acsi-competitors';
                         let data = {
                             idsportingclub: this.idsportingclubmodal,
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti rimossi correttamente', {
                                               title: 'Partecipanti rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.sportingclubacsicompetitors.refresh();
                                           this.is_updating_sportingclubs = true;
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione dei partecipanti.', {
                                               title: 'Errore nella rimozione dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare la rimozione selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         confirmDeleteSportingclub(idsportingclub) {
             Swal.fire({
                 title: 'Conferma eliminazione Sodalizio',
                 text: "Sei sicuro di voler eliminare il sodalizio selezionato e tutti i suoi partecipanti?",
                 icon: 'error',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/rseps/subscription/sportingclub/remove-sportingclub';
                     let data = {
                         idsportingclub: idsportingclub,
                         idcompetition: this.competition.id,
                         competitiontype: this.competition.type,
                     };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Sodalizio eliminato correttamente', {
                                           title: 'Sodalizio eliminato correttamente',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.is_updating_sportingclubs = true;
                                       this.updateCompetition();
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione del sodalizio', {
                                           title: 'Errore nell\'eliminazione del sodalizio',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }
                               })
                               .catch(({response}) => {
                                   console.log(response);
                               });

                     this.loading = false;
                 }
             });
         },
     },
 };
</script>

<style>
 ul.nav-tabs .nav-item a.nav-link {
     height: 100%;
 }

 .title-0 {
     margin-bottom: 0px !important;
     padding-bottom: 0px !important;
     min-height: 0px !important;
 }

 .card-header.title-0 {
     height: 0px !important;
     overflow: hidden !important;
     padding-top: 0px !important;
 }

 .modal-body {
     padding-top: 0px !important;
 }

 #epsupload .upload-trigger {
     width: 100%;
     cursor: pointer;
 }

 #epsupload .upload-zone {
     width: 100%;
     cursor: pointer;
 }
</style>
